



































































































































































































import { Vue, Component } from "vue-property-decorator";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { userServices } from "@/services/user.services";
import {
  ResponseListOfMenus,
  CreateMenuPrivilege,
  ResponseListOfPrivilege,
} from "@/models/interface/user.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { Messages } from "@/models/enums/messages.enum";
import { getauthorities } from "@/helpers/cookie";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { debounce } from "@/helpers/debounce";
@Component
export default class ListMenu extends Vue {
  nameget = "" as string;
  authPrivilege = [] as string[];
  loadingTable = false as boolean;
  switchActive = true as boolean | undefined;
  dataDetail = "" as string;
  dataListMenu = {} as ResponseListOfMenus;
  dataListPrivilege = {} as ResponseListOfPrivilege;
  typemodal = "" as string;
  isFormSubmitted = false as boolean;
  selectedRowKeysModal = [] as number[];
  page = 0 as number;
  limit = 10 as number;
  search = "" as string;
  sort = "" as string;
  trigerdisable = false as boolean;
  titlemodalOpen = "" as string;
  direction = "" as string;
  pageSizeSet = 10;
  payloadPagination = {};
  defaultPagination = false as boolean;
  modalOpen = false as boolean;
  form = this.$form.createForm(this, { name: "addMenuForm" });
  tempPrivilegeid = [] as string[];
  optionModal = [] as string[];
  columnsTable = [
    {
      title: this.$root.$t("lbl_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 150,
      sortDirections: ["ascend", "descend", null],
      scopedSlots: { customRender: "isClick" },
    },
    {
      title: this.$root.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: 200,
      scopedSlots: { customRender: "isNull" },
      sortDirections: ["ascend", "descend", null],
    },
    {
      title: this.$root.$t("lbl_active"),
      dataIndex: "active",
      key: "active",
      width: 60,
      scopedSlots: { customRender: "isEnable" },
    },
  ] as ColumnTableCustom[];
  columnsTableModal = [
    {
      title: this.$root.$t("lbl_privilege"),
      dataIndex: "privilege",
      key: "menu",
      width: 220,
      scopedSlots: { customRender: "menu" },
      responsiveColSelect: [
        {
          name: "menu",
          placeholder: this.$root.$t("lbl_privilege"),
          style: "width: 100%;",
          value: "id",
          options: [] as any[],
        },
      ],
    },
    {
      title: this.$root.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
    },
  ] as any[];
  dataSourceModal = [] as CreateMenuPrivilege[];
  formRules = {
    username: {
      label: "lbl_name",
      name: "name",
      placeholder: "lbl_name_placeholder",
      decorator: [
        "username",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    name: {
      label: "lbl_name",
      name: "name",
      placeholder: "lbl_name_placeholder",
      decorator: [
        "name",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: ["description", {}],
    },
    active: {
      label: "lbl_active",
      name: "active",
      decorator: ["active"],
    },
  };
  mounted() {
    this.getListOfMenu("", false);
  }
  created() {
    const auth = getauthorities();
    auth.map(dataAuth => {
      if (dataAuth?.name === "menu") {
        this.authPrivilege = dataAuth.value;
        this.columnsTable.push({
          title: this.$root.$t("lbl_action").toString().toString(),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["update"],
          width: 120,
          align: "center",
          fixed: "right",
        });
      }
    });
  }

  showConfirmation() {
    if (this.selectedRowKeysModal.length > 0) {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeysModal.length} items will be deleted.`,
        onOk: () => {
          this.handleDeleteRow();
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }
  handleCLickFLoating() {
    this.createNew();
  }
  ResetFilter(getlist: boolean) {
    this.direction = "";
    this.search = "";
    if (getlist) this.getListOfMenu("", false);
  }
  reponseEditTable(response) {
    this.trigerdisable = false;
    this.typemodal = "edit";
    this.titlemodalOpen = "lbl_edit_menu";
    this.dataDetail = "/" + response.data.id;
    this.resetCheckbox();
    this.ResetFilter(false);
    this.getListPrivilege(true, "");
    this.getListOfMenu("/" + response.data.id, false);
  }
  editModal() {
    this.trigerdisable = false;
    this.typemodal = "edit";
    this.titlemodalOpen = "lbl_edit_menu";
    this.dataSourceModal.forEach(item => {
      item.disabled = false;
    });
    this.getListPrivilege(true, "");
    this.resetCheckbox();
  }
  getListPrivilege(modal, value) {
    let params = {
      limit: 10,
      page: 0,
      search: "active~true",
    } as RequestQueryParamsModel;
    if (value)
      params.search += `_AND_authority~*${value}*_OR_description~*${value}*`;
    userServices
      .listOfPrivilege(params)
      .then((res: ResponseListOfPrivilege) => {
        res.data.forEach((dataObject, index) => {
          dataObject.key = index;
          dataObject.name = dataObject.authority;
        });
        this.dataListPrivilege = res;
        const findColumn = this.columnsTableModal.find(
          column => column.responsiveColSelect
        )?.responsiveColSelect;
        findColumn[0].options = res.data;
        this.modalOpen = modal;
      });
  }
  handleSearchSelectTable(value, _key, _col) {
    this.getListPrivilege(true, value);
  }
  handleSelectTableModal(value, _key, _col, recordOptions) {
    this.dataSourceModal[_key] = {
      ...this.dataSourceModal[_key],
      privilegeId: value,
      id: value,
    };
    const option = recordOptions.find(data => data.id === value);
    this.dataSourceModal[_key] = {
      ...this.dataSourceModal[_key],
      description: option?.description ? option?.description : "-",
    };
    this.dataSourceModal = this.dataSourceModal.slice();
    this.dataSourceModal[_key].privilege = option ? option.authority : "";
    this.dataSourceModal[_key].id = value;
    this.dataSourceModal[_key].description = option ? option.description : "";
  }
  resetCheckbox() {
    this.selectedRowKeysModal = [];
  }
  onSelectChangeModal(selectedRowKeysModal) {
    this.selectedRowKeysModal = selectedRowKeysModal;
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.getListOfMenu("", true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.getListOfMenu("", true);
  }
  reponseSearchInput(response) {
    if (response)
      this.search = "name~*" + response + "*_OR_description~*" + response + "*";
    else this.search = "";
    this.getListOfMenu("", false);
  }
  createNew() {
    this.resetCheckbox();
    this.form.resetFields();
    this.dataSourceModal = [];
    this.trigerdisable = false;
    this.typemodal = "create";
    this.titlemodalOpen = "lbl_create_new_menu";
    this.ResetFilter(false);
    this.getListPrivilege(true, "");
  }

  handleCancel() {
    this.modalOpen = false;
  }
  onSorterChange(response) {
    if (response.sort.order) {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
    } else {
      this.ResetFilter(false);
    }
    this.getListOfMenu("", false);
  }
  getListOfMenu(path: string, page) {
    let params;
    if (!path) {
      params = {
        limit: this.limit,
        page: page ? this.page : 0,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
    }
    this.loadingTable = true;
    userServices
      .listOfMenus(params, path)
      .then((res: ResponseListOfMenus) => {
        if (!path) {
          res.data.map((dataObject, index) => (dataObject.key = index));
          this.dataListMenu = res;
        } else {
          debounce(() => {
            this.form.setFieldsValue({
              name: res.name,
              description: res.description ? res.description : "-",
            });
          }, 200);
          this.switchActive = res.active;
          res.privileges.map(
            (dataObject, index) => (
              (dataObject.key = index),
              (dataObject.name = dataObject.authority),
              (dataObject.privilege = dataObject.authority),
              (dataObject.privilegeId = dataObject.id),
              (dataObject.disabled = false)
            )
          );
          this.dataSourceModal = res.privileges;
        }
      })
      .finally(() => (this.loadingTable = false));
  }
  handleAddRow() {
    const dataColumn: CreateMenuPrivilege = {
      privilege: null,
      description: "",
      key: this.dataSourceModal.length,
      id: "",
      disabled: false,
      privilegeId: "",
    };
    this.dataSourceModal = [...this.dataSourceModal, dataColumn];
  }
  handleDeleteRow() {
    this.dataSourceModal = this.dataSourceModal.filter(data => {
      return !this.selectedRowKeysModal.includes(data.key);
    });
    this.dataSourceModal.forEach((data, index) => (data.key = index));
    this.selectedRowKeysModal = [];
  }
  submitForm(e, type) {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        this.tempPrivilegeid = [];
        this.dataSourceModal.forEach(item => {
          if (item.id) {
            this.tempPrivilegeid.push(item.id);
          }
        });
        const checkduplicatedata = this.tempPrivilegeid.some(
          (element, index) => {
            return this.tempPrivilegeid.indexOf(element) !== index;
          }
        );
        if (!checkduplicatedata) {
          const datapost = {
            name: values.name,
            description: values.description,
            privilegeIds: this.tempPrivilegeid,
            active: this.switchActive,
          };
          this.isFormSubmitted = true;
          switch (type) {
            case "create":
              userServices
                .createMenu(datapost)
                .then(res => {
                  if (res) {
                    this.modalOpen = false;
                    this.getListOfMenu("", false);
                    this.form.resetFields();
                    this.$notification.success({
                      description: Messages.CREATE_SUCCESS,
                      message: "Success",
                      duration: 30,
                    });
                  }
                })
                .catch(error => {
                  this.$notification.error({
                    description: error.details,
                    message: "Error",
                    duration: 30,
                  });

                  this.$notification.error({
                    description: Messages.CREATE_FAIL,
                    message: "Error",
                    duration: 30,
                  });

                  this.isFormSubmitted = false;
                })
                .finally(() => (this.isFormSubmitted = false));
              break;
            case "edit":
              userServices
                .updateMenu(datapost, this.dataDetail)
                .then(res => {
                  if (res) {
                    this.modalOpen = false;
                    this.getListOfMenu("", false);
                    this.form.resetFields();
                    this.$notification.success({
                      description: Messages.UPDATE_SUCCESS,
                      message: "Success",
                      duration: 30,
                    });
                  }
                })
                .catch(error => {
                  this.isFormSubmitted = false;
                  this.$notification.error({
                    description: error.details,
                    message: "Error",
                    duration: 30,
                  });

                  this.$notification.error({
                    description: Messages.UPDATE_FAIL,
                    message: "Error",
                    duration: 30,
                  });
                })
                .finally(() => (this.isFormSubmitted = false));
              break;
            default:
              break;
          }
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Cannot Duplicate Data",
          });
        }
      } else {
        this.$notification["error"]({
          message: "Error",
          description: "Form name is mandatory",
        });
      }
    });
  }
}
